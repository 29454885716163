import { render, staticRenderFns } from "./FavoriteGaleryItem.vue?vue&type=template&id=6ac20bd5&scoped=true"
import script from "./FavoriteGaleryItem.vue?vue&type=script&lang=js"
export * from "./FavoriteGaleryItem.vue?vue&type=script&lang=js"
import style0 from "./FavoriteGaleryItem.vue?vue&type=style&index=0&id=6ac20bd5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac20bd5",
  null
  
)

export default component.exports