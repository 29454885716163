<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <HeaderComponent :title="$t('event.favorites')" />

      <section class="favoritesGallery">
        <template v-if="!favsLoaded">
          <div class="m-3">
            <b-skeleton size="is-large" height="100%"/>
          </div>
        </template>
        <template v-else>
          <div v-if="favs.length === 0" class="empty-state mt-5">
            <div class="px-5">
              <h5 class="title is-size-5">{{ $t('event.noSavedImages') }}</h5>
              <p class="subtitle is-size-6" v-html="$t('event.visitGallery')"></p>
            </div>
              <div class="empty-state-image m-5">
                <d-icon icon="FaImage" size="is-medium" />
              </div>
          </div>
          
          <template v-else-if="favs.length === 1">
            <FavoriteGaleryItem
              
              :fav="favs[0]"
              class="m-3 p-3"
              @open-image="OpenImage"
            />
          </template>
          <template v-else>
            <vue-glide
              type="carousel"
              :perView="1"
              :peek="50"
              :gap="5"
              :key="sliderId"
              v-model="activeSlide"
            >
              <vue-glide-slide v-for="fav in favs" :key="fav.id + 1">
                <FavoriteGaleryItem
                  :fav="fav"
                  class="m-3"
                  @open-image="OpenImage"
                />
              </vue-glide-slide>
            </vue-glide>
          </template>
        </template>
      </section>
    </main>
    <!-- Opened Image -->
    <b-modal v-model="isImageOpen">
      <div class="card" v-if="openedMedia">
        <div class="card-image p-2">
          <div
            v-if="openedMedia.media.relationTo == 'user-images'"
            class="media-container"
          >
            <img
              :src="openedMedia.media.value.url"
              :width="openedMedia.media.value.width ?? '100%'"
              :height="openedMedia.media.value.height ?? '100%'"
              class="preview-image"
            />
          </div>

          <div
            v-if="openedMedia.media.relationTo == 'user-videos'"
            class="media-container"
          >
            <video
              :src="`${openedMedia.media.value.url}#t=0.1`"
              preload="auto"
              disableRemotePlayback
              muted
              playsinline
              autoplay
              loop
              class="preview-image"
            />
          </div>

          <div class="actions">
            <StarFavoriteMedia
              :collection="openedMedia?.media.relationTo"
              :mediaId="openedMedia?.media.value.id"
              @removed="FavoriteRemoved()"
            />
            <ShareMedia
              :imageSrc="openedMedia?.media.value.url"
            />
          </div>

          <div v-if="openedMedia?.media.relationTo == 'photofeed'">
            <div class="favorite-description">
              <div class="favorite-description">
                {{ currentDoc?.media.value.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-glide-js/dist/vue-glide.css";
import { Glide, GlideSlide } from "vue-glide-js";

import HeaderComponent from "../components/HeaderComponent";
import FavoriteGaleryItem from "@/components/FavoriteGaleryItem.vue";
import ShareMedia from "@/components/ShareMedia.vue";
import StarFavoriteMedia from "@/components/StarFavoriteMedia.vue";

export default {
  components: {
    HeaderComponent,
    FavoriteGaleryItem,
    ShareMedia,
    StarFavoriteMedia,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data() {
    return {
      sliderId: 0,
      activeSlide: 0,

      isImageOpen: false,
      openedMedia: null,

      refetchTimeout: null,
    };
  },
  methods: {
    FavoriteRemoved() {
      this.isImageOpen = false;
    },
    GoToMenu() {
      this.$router.push("/");
    },
    OpenImage(fav) {
      this.openedMedia = fav;
      this.isImageOpen = true;
    },
    RefetchFavorites() {
      this.refetchTimeout = setTimeout(() => {
        if (this.anyMediaPending) {
          this.$store.dispatch("user/LoadFavorites", true);
          this.RefetchFavorites();
        }
      }, 10000);
    },
  },
  computed: {
    favsLoaded() {
      return this.$store.state.user.favoritesLoaded;
    },
    favs() {
      return this.$store.state.user.favorites;
    },
    currentDoc() {
      return this.favs[this.activeSlide];
    },
    backgroundImage() {
      if (this.$store.state.setup.headerBackground)
        return `background-image: url(${this.$store.state.setup.headerBackground.url})`;
      return null;
    },
    anyMediaPending() {
      return this.favs.some((fav) => fav.mediaPending);
    },
  },
  watch: {
    favs() {
      this.sliderId++;
      if (this.anyMediaPending) {
        this.RefetchFavorites();
      }
    },
  },
  sockets: {
    sockets: {
      UserMediaUpdated(data) {
        console.log("UserMediaUpdated", data);
        this.$store.commit("user/UpdateFavorite", data.doc);
      },
    },
  },
  async mounted() {
    this.$socket.client.emit(
      "player:JoinSpace",
      { spaceSlug: "favorites" },
      () => {}
    );
    await this.$store.dispatch("user/LoadFavorites", true);
    this.$store.commit("StopLoading");
  },
  beforeMount() {
    if (!this.$store.state.setup.event.favoriteGallery?.enabled) {
      console.log("Acceso restringido");
      this.$router.push("/");
    }
  },
  beforeDestroy(){
    if(this.refetchTimeout) {
      clearTimeout(this.refetchTimeout);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
// Home menu
.home-menu-header {
  align-items: center;
  justify-content: center;
  background-color: $home-menu-header;
  background-size: auto 100%;
  color: $black-bis;
}

.logo {
  max-height: 100%;
  max-height: 50px;
}

.title {
  font-size: 1.2em;
  font-weight: bolder;
  line-height: 1.2;
}

.media-container {
  display: flex;
  justify-content: center;
}

.actions {
  display: flex;
  padding: 0.6rem;
}

.glide {
  .glide__slide {
    position: relative;
  }
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}
.thumb-container {
  position: relative;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
  span {
    // tamaño icono
    width: 4rem;
    height: 4rem;
  }
}

.b-skeleton {
  width: calc(100% - 100px);
  margin-inline: 3rem;
  aspect-ratio: 1;
}

.preview-image {
  background: #f5f5f5;
  height: 60dvh;
  object-fit: contain;
}

.empty-state-image{
  color: silver;
  border-radius: $general-border-radius;
}
.empty-state-image,
.processing {
  aspect-ratio: 1;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
</style>
