<template>
  <div v-if="fav.mediaPending">
    <div class="thumb-container processing">
      <div>Tu archivo se está procesando</div>
      <d-icon icon="FaSpinnerThird" class="fa-rotate" size="is-medium" />
    </div>
  </div>
  <div v-else>
    <div
      class="thumb-container"
      v-if="fav.media.relationTo == 'user-images'"
      @click="OpenImage(fav)"
    >
      <img :src="fav.media.value.sizes.high.url" />
    </div>

    <div
      class="thumb-container"
      v-if="fav.media.relationTo == 'user-videos'"
      @click="OpenImage(fav)"
    >
      <img :src="fav.media.value.thumbnail.sizes.hd.url" />
      <div class="play-icon">
        <d-icon icon="FaPlay" size="large" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fav: {
      type: Object,
      required: true,
    },
  },
  methods: {
    OpenImage(fav) {
      this.$emit("open-image", fav);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.thumb-container {

background-color: #f5f5f5;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: $general-border-radius;
gap: .5rem;

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

}

&.processing {
    aspect-ratio: 1;

    d-icon {
    margin-top: 1rem;
    }
}

  img {
    display: block;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: $general-border-radius;
  }
}
</style>
